import 'babel-polyfill';

import React from 'react';
import { Link } from 'react-router-dom';

export default class Button extends React.Component {
    render() {
        return (
            <div className={`button ${this.props.color}`}>
                <Link to={this.props.link} onClick={this.props.onClick}>
                    <div className="button--inner">
                        {this.props.direction == 'left' ? <span className="button--ico--left"></span> : ''}
                        <span className="button--title text--uppercase">{this.props.title}</span>
                        {this.props.direction == 'right' ? <span className="button--ico--right"></span> : ''}
                    </div>
                </Link>
            </div>
        );
    }
}


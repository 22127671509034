export const scrollTo = (element, to, duration) => {
    if (duration <= 0) return;
    var difference = to - element.scrollTop;
    var perTick = difference / duration * 10;

    if (window.navigator.userAgent.indexOf("Edge") > -1) {
        window.scroll(0, to);
    } else {

        setTimeout(function () {

            element.scrollTop = element.scrollTop + perTick;
            window.scroll(0, element.scrollTop + perTick);
            if (element.scrollTop === to) return;
            scrollTo(element, to, duration - 10);
        }, 10);

    }
}
import 'babel-polyfill';

import React from 'react';

export default class Countdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            endDate: new Date(this.props.time.time.endDate).getTime(),
            actualDate: new Date(this.props.time.time.actualDate).getTime(),
            endCount: this.props.time.time.endCount
        };
    }

    componentDidMount() {
        this.setInterval();
    }

    setInterval() {
        this.intervalId = setInterval(() => {
            this.setState({
                actualDate: this.state.actualDate + 1000
            }, () => {
                this.setDate();

            });
        }, 1000);
    }

    setDate() {
        const date = this.state.endDate;
        const actualDate = this.state.actualDate;
        const leftTime = date - actualDate;

        let sec, min, h, day, secLeft, minLeft, hLeft, dayLeft;

        if (leftTime > 0) {
            sec = leftTime / 1000;
            min = sec / 60;
            h = min / 60;
            day = h / 24;

            secLeft = Math.floor(sec % 60);
            minLeft = Math.floor(min % 60);
            hLeft = Math.floor(h % 24);
            dayLeft = Math.floor(day);

            if (minLeft < 10) minLeft = `0${minLeft}`;

            if (secLeft < 10) secLeft = `0${secLeft}`;

            if (hLeft < 10) hLeft = `0${hLeft}`;

            if (dayLeft < 10) dayLeft = `0${dayLeft}`;

            this.setState({
                days: dayLeft,
                hours: hLeft,
                minutes: minLeft,
                seconds: secLeft
            });

        }

    }

    render() {
        return (
            <div className="countdown">
                <div className="countdown--title color--grey">Do startu konkursu pozostało</div>
                <div className="countdown--box border--all color--light-grey">
                    <div className="countdown--days border--right color--light-grey">
                        <span className="number color--orange">{this.state.days}</span>
                        <span className="info color--grey text--uppercase">dni</span>
                    </div>
                    <div className="countdown--hours border--right color--light-grey">
                        <span className="number color--orange">{this.state.hours}</span>
                        <span className="info color--grey text--uppercase">h</span>
                    </div>
                    <div className="countdown--minutes border--right color--light-grey">
                        <span className="number color--orange">{this.state.minutes}</span>
                        <span className="info color--grey text--uppercase">min</span>
                    </div>
                    <div className="countdown--seconds">
                        <span className="number color--orange">{this.state.seconds}</span>
                        <span className="info color--grey text--uppercase">sek</span>
                    </div>
                </div>
            </div>
        );
    }
}


import 'babel-polyfill';

import React from 'react';
import Button from '../components/button';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { scrollTo } from '../helpers/scrollTo';

require('es6-promise').polyfill();
require('fetch-everywhere');

export default class FormStepTwo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            required: [],
            optional: [],
            info: [],
            award: this.props.globe.selectAwardId,
            answer: this.props.globe.answer,
            fbApi: this.props.globe.fb,
            psid: this.props.globe.psid,
            code: '',
            codeValid: false,
            name: '',
            nameValid: false,
            surname: '',
            surnameValid: false,
            city: '',
            cityValid: false,
            phone: '',
            phoneValid: false,
            email: '',
            emailValid: false,
            term1: false,
            term2: false,
            term3: false,
            require: false,
            thanks: false,
            captcha: false,
            send: false,
            webview: false,
            string: ''
        }


    }
    componentDidMount() {

        scrollTo(document.documentElement, 0, 300);

        if (this.state.answer.length > 0) {

            this.getData();

            document.querySelector('head title').innerText = 'Cheetos - Konkurs Fashionista - wpisz kod i uzupełnij dane';
            document.querySelector('head meta[name="description"]').setAttribute('content', 'Weź udział w konkursie Fashionista i zawalcz o nagrody! Uzupełnij kod znajdujący się wewnątrz opakowania i uzupełnij swoje dane. ');
            document.querySelector('head meta[name="keywords"]').setAttribute('content', 'cheetos, fashionista, nagrody, nagroda, konkurs, kod');

            if (window.location.search.indexOf('webview') > -1) {
                this.setState({
                    captcha: true,
                    webview: true
                })
            }

        } else {
            if (window.location.search.indexOf('webview') > -1) {
                window.location.href = `/${window.location.search}#/formularz/krok-1`;

            } else {
                window.location.href = '/#/formularz/krok-1';

            }
        }


    }


    onChange = value => {

        if (value != null) {

            if (value.length > 0) {
                this.setState({
                    captcha: true,
                    string: value
                })
            }
        }
    }

    getData() {
        fetch('/api/v1/agreements?_format=json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    required: resp.required,
                    optional: resp.optional,
                    info: resp.info
                });
            });
    }

    postFb() {

        const data = {
            psid: this.state.psid
        }

        fetch(this.state.fbApi[0].field_api_url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {
                MessengerExtensions.requestCloseBrowser();
            });
    }



    postData() {
        const data = {
            code1: this.state.code,
            email: this.state.email,
            nagrodaId: this.state.award,
            imie: this.state.name,
            nazwisko: this.state.surname,
            miejscowosc: this.state.city,
            telefon: this.state.phone,
            tresc: this.state.answer,
            term1: this.state.term1,
            term2: this.state.term2,
            term3: this.state.term3,
            webview: this.state.webview,
            recaptcha: this.state.string
        };
        const webview = (window.location.search.indexOf('webview') > -1);


        fetch('api/v1/reqlot?_format=json', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => {
                console.error('Error:', error)
                if (webview == true) {

                    fetch(`https://konkursfashionistabot.cheetos.pl/webhook/callback/262b0cb52f8ec92126cda03d116fac2e/${this.state.psid}`, {
                        method: 'POST',
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest'
                        }
                    }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            MessengerExtensions.requestCloseBrowser();
                        });
                }
            })
            .then(response => {
                if (response.recaptcha == false) {
                    this.setState({
                        captcha: false
                    })
                    const endCaptcha = document.getElementsByClassName('form-question step-two')[0].offsetTop - 30;
                    scrollTo(document.documentElement, endCaptcha, 300);
                }
                if (response.validate_coupons == 1 && response.validate_email == 1) {
                    if (response.hash.length > 0) {
                        this.setState({
                            thanks: true
                        })
                        if (webview == true) {
                            fetch(`https://konkursfashionistabot.cheetos.pl/webhook/callback/06a81307e869a7f3f25a51daaaea8847/${this.state.psid}`, {
                                method: 'POST',
                                headers: {
                                    'X-Requested-With': 'XMLHttpRequest'
                                }
                            }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    MessengerExtensions.requestCloseBrowser();
                                });
                        }
                    }
                } else {
                    if (response.validate_coupons == 0) {
                        document.querySelector('#code').classList.add('error');
                        this.setState({
                            codeValid: false
                        })
                        const endCoupons = document.getElementsByClassName('form-question step-two')[0].offsetTop - 30;

                        scrollTo(document.documentElement, endCoupons, 300);
                    }

                    if (response.validate_email == 0) {
                        document.querySelector('#email').classList.add('error');
                        this.setState({
                            emailValid: false
                        })
                        const endEmail = document.getElementsByClassName('form-question step-two')[0].offsetTop - 30;

                        scrollTo(document.documentElement, endEmail, 300);
                    }
                }
            });
    }

    onlyNumber = e => {
        const charCode = (e.which) ? e.which : e.keyCode;
        if ([8, 9, 46, 37, 39, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 35, 36, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105].indexOf(charCode) == -1) {
            e.returnValue = false;
            if (e.preventDefault) { e.preventDefault(); }
        }
    }

    moreEvents = e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.previousElementSibling.classList.contains('hidden') == true) {
            e.target.previousElementSibling.classList.remove('hidden');
            e.target.innerHTML = 'mniej';
        } else {
            e.target.previousElementSibling.classList.add('hidden');
            e.target.innerHTML = 'wiecej';
        }
    }

    checkboxEvent = e => {
        e.stopPropagation();

        if (e.currentTarget.classList.contains('checked') == true) {
            e.currentTarget.classList.remove('checked');
        } else {
            e.currentTarget.classList.add('checked');
        }

    }

    handler = e => {
        e.preventDefault();
        this.validation();
    }

    validation() {

        if (document.querySelector('#code').value.length == 0) {
            document.querySelector('#code').classList.add('error')
        } else {
            document.querySelector('#code').classList.remove('error')
            this.setState({
                code: document.querySelector('#code').value,
                codeValid: true
            })
        }

        if (document.querySelector('#name').value.length == 0) {
            document.querySelector('#name').classList.add('error')
        } else {
            document.querySelector('#name').classList.remove('error')
            this.setState({
                name: document.querySelector('#name').value,
                nameValid: true
            })
        }

        if (document.querySelector('#surname').value.length == 0) {
            document.querySelector('#surname').classList.add('error')
        } else {
            document.querySelector('#surname').classList.remove('error')
            this.setState({
                surname: document.querySelector('#surname').value,
                surnameValid: true
            })
        }

        if (document.querySelector('#city').value.length == 0) {
            document.querySelector('#city').classList.add('error')
        } else {
            document.querySelector('#city').classList.remove('error')
            this.setState({
                city: document.querySelector('#city').value,
                cityValid: true
            })
        }

        if (document.querySelector('#phone').value.length != 9) {
            document.querySelector('#phone').classList.add('error')
            this.setState({
                phoneValid: false
            })
        } else {
            var stringPhone = document.querySelector('#phone').value;
            stringPhone = stringPhone.replace(/\D/g, '');
            if (stringPhone.length != 9) {
                document.querySelector('#phone').classList.add('error')
                this.setState({
                    phoneValid: false
                })
            } else {
                document.querySelector('#phone').classList.remove('error')

                this.setState({
                    phone: document.querySelector('#phone').value,
                    phoneValid: true
                })
            }
        }

        if (document.querySelector('#email').value.length == 0) {
            document.querySelector('#email').classList.add('error')
        } else {
            document.querySelector('#email').classList.remove('error')
            this.setState({
                email: document.querySelector('#email').value,
                emailValid: true
            })
        }

        if (document.querySelector('.required-box--elems .checkbox-single').classList.contains('checked') == false) {
            document.querySelector('.required-box--elems .checkbox-single').classList.add('error')
        } else {
            document.querySelector('.required-box--elems .checkbox-single').classList.remove('error')
            this.setState({
                require: true
            })
        }
        if (document.querySelector('.optional-box--elems .checkbox-single.term-1').classList.contains('checked') == true) {
            this.setState({
                term1: true
            })
        }
        if (document.querySelector('.optional-box--elems .checkbox-single.term-2').classList.contains('checked') == true) {
            this.setState({
                term2: true
            })
        }
        if (document.querySelector('.optional-box--elems .checkbox-single.term-3').classList.contains('checked') == true) {
            this.setState({
                term3: true
            })
        }

        this.setState({
            send: true
        })

        setTimeout(() => {
            this.submitForm();
        }, 1000)

    }

    checkCodeAndMail() {

        const data = {
            code1: this.state.code,
            email: this.state.email
        }

        fetch('/api/v1/application_validation?_format=json', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {
                if (response.validate_coupons == 0) {
                    document.querySelector('#code').classList.add('error');
                    this.setState({
                        codeValid: false
                    })
                    const endCoupons = document.getElementsByClassName('form-question step-two')[0].offsetTop - 30;

                    scrollTo(document.documentElement, endCoupons, 300);
                } else {
                    this.setState({
                        codeValid: true
                    })
                }

                if (response.validate_email == 0) {
                    document.querySelector('#email').classList.add('error');
                    this.setState({
                        emailValid: false
                    })
                    const endEmail = document.getElementsByClassName('form-question step-two')[0].offsetTop - 30;

                    scrollTo(document.documentElement, endEmail, 300);
                } else {
                    this.setState({
                        emailValid: true
                    })
                }
            });
    }

    backToMain() {
        if (typeof ga === 'function') ga('send', 'event', 'Krok 3', 'Potwierdzenie', 'Wroc do strony glownej');
    }

    close() {
        if (typeof ga === 'function') ga('send', 'event', 'Krok 3', 'Potwierdzenie', 'Zamknij webview');

    }

    submitForm() {
        const webview = (window.location.search.indexOf('webview') > -1);
        if (this.state.codeValid == true &&
            this.state.nameValid == true &&
            this.state.surnameValid == true &&
            this.state.cityValid == true &&
            this.state.phoneValid == true &&
            this.state.emailValid == true &&
            this.state.require == true &&
            this.state.captcha == true) {
            this.postData();

            if (typeof ga === 'function') ga('send', 'event', 'Krok 2', 'Wysłanie zgłoszenia', 'Wyślij', 1);
            /* if (webview == true) {
                this.postFb();

            } */

        } else {
            if (typeof ga === 'function') ga('send', 'event', 'Krok 2', 'Wysłanie zgłoszenia', 'Wyślij', 0);
            const end = document.getElementsByClassName('form-question step-two')[0].offsetTop - 30;

            if (this.state.emailValid == true && this.state.codeValid) {
                this.checkCodeAndMail();
            }


            scrollTo(document.documentElement, end, 300);

        }
    }

    render() {
        const webview = (window.location.search.indexOf('webview') > -1);
        let recaptchaInstance;

        const resetRecaptcha = () => {
            recaptchaInstance.reset();
        };

        return (
            <div className="form-question step-two bg--lightest-grey">
                <div className="form-question--inner">
                    <div className="code-section">
                        <h2 className="color--grey">Wpisz kod z opakowania:</h2>
                        <input type="text" id="code" />
                        {(!this.state.codeValid && this.state.send) &&
                            <div className="error color--red">Kod, który wpisałeś jest niepoprawny.</div>
                        }

                    </div>
                    <div className="form-form">
                        <h2 className="color--grey">Uzupełnij dane kontaktowe:</h2>
                        <div className="form-form-row">
                            <div className="single-input">
                                <label className="color--grey" for="name">Imię</label>
                                <input type="text" id="name" />
                                {(!this.state.nameValid && this.state.send) &&
                                    <div className="error color--red">Twoje dane są niepoprawne.</div>
                                }
                            </div>
                            <div className="single-input">
                                <label className="color--grey" for="surname">Nazwisko</label>
                                <input type="text" id="surname" />
                                {(!this.state.surnameValid && this.state.send) &&
                                    <div className="error color--red">Twoje dane są niepoprawne.</div>
                                }
                            </div>
                        </div>
                        <div className="form-form-row">
                            <div className="single-input">
                                <label className="color--grey" for="city">Miejscowość</label>
                                <input type="text" id="city" />
                                {(!this.state.cityValid && this.state.send) &&
                                    <div className="error color--red">Twoje dane są niepoprawne.</div>
                                }
                            </div>
                            <div className="single-input">
                                <label className="color--grey" for="phone">Numer telefonu</label>
                                <input type="text" id="phone" onKeyDown={this.onlyNumber} maxLength="9" />
                                {(!this.state.phoneValid && this.state.send) &&
                                    <div className="error color--red">Twoje dane są niepoprawne. Wprowadź numer w formacie: 123456789</div>
                                }
                            </div>
                        </div>
                        <div className="form-form-row">
                            <div className="single-input">
                                <label className="color--grey" for="email">E-mail</label>
                                <input type="text" id="email" />
                                {(!this.state.emailValid && this.state.send) &&
                                    <div className="error color--red">Twoje dane są niepoprawne.</div>
                                }
                            </div>
                            {webview == false &&
                                <div className="single-input recaptcha">
                                    <ReCAPTCHA
                                        ref="recaptcha"
                                        sitekey="6LfO0FUUAAAAAF8ibGS1jHsLTJBgokD-pm1G8WIj"
                                        onChange={this.onChange}
                                    />
                                    {(!this.state.captcha && this.state.send) &&
                                        <div className="error color--red">Aby przejść dalej, musisz zaznaczyć pole captcha.</div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="form-approval">
                        {this.state.required.length > 0 &&
                            <div className="required-box">
                                <h3 className="color--grey text--uppercase">Oświadczenie wymagane</h3>
                                <div className="required-box--elems">
                                    {(!this.state.require && this.state.send) &&
                                        <div className="error color--red">Aby przejść dalej, musisz zaznaczyć wszystkie obowiązkowe pola.</div>
                                    }
                                    {this.state.required.map(elem => {
                                        return <div className="checkbox-single" onClick={this.checkboxEvent}>
                                            <span dangerouslySetInnerHTML={{ __html: elem.content }}></span>
                                            <span className="hidden" dangerouslySetInnerHTML={{ __html: elem.content_more }}></span>
                                            {elem.content_more != null &&
                                                <div className="more color--orange" onClick={this.moreEvents} data-ga-cat="Krok 2" data-ga-act="Regulamin" data-ga-lab="Więcej">więcej</div>
                                            }

                                        </div>
                                    })}
                                </div>
                            </div>
                        }
                        {this.state.optional.length > 0 &&
                            <div className="color--grey optional-box">
                                <h3 className="color--grey text--uppercase">Dobrowolne zgody marketingowe</h3>
                                <div className="optional-box--elems">
                                    {this.state.optional.map((elem, index) => {
                                        return <div className={`checkbox-single term-${index + 1}`} onClick={this.checkboxEvent}>
                                            <span dangerouslySetInnerHTML={{ __html: elem.content }}></span>
                                            <span className="hidden" dangerouslySetInnerHTML={{ __html: elem.content_more }}></span>
                                            {elem.content_more != null &&
                                                <div className="more color--orange" onClick={this.moreEvents}>więcej</div>
                                            }
                                        </div>
                                    })}
                                </div>
                            </div>
                        }
                        {this.state.info.length > 0 &&
                            <div className="color--grey info-box">
                                <h3 className="color--grey text--uppercase">Klauzula informacyjna do zgód dobrowolnych</h3>
                                <div className="info-box--elems">
                                    {this.state.info.map(elem => {
                                        return <div className="info-single">
                                            <span dangerouslySetInnerHTML={{ __html: elem.content }}></span>
                                            <span className="hidden" dangerouslySetInnerHTML={{ __html: elem.content_more }}></span>
                                            {elem.content_more != null &&
                                                <div className="more color--orange" onClick={this.moreEvents} data-ga-cat="Krok 2" data-ga-act="Klauzula" data-ga-lab="Więcej">więcej</div>
                                            }
                                        </div>
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="form-question--button-box">
                        <Link to="/formularz/krok-1" className="color--orange return" data-ga-cat="Krok 2" data-ga-act="Poprzedni krok">Poprzedni krok</Link>
                        <Button direction="right" color="orange" title="Wyślij" onClick={this.handler} link="/formularz/krok-3" />
                    </div>
                </div>
                {this.state.thanks == true &&
                    <div className="form--thanks">
                        <div className="form--thanks--inner bg--white">
                            <h2 className="color--grey">Twoje zgłoszenie zostało wysłane</h2>
                            <div className="color--grey info">Każdego dnia Komisja Chestera wybierze 100 najlepszych odpowiedzi (po 20 dla każdej nagrody), których autorzy położą łapki na wychrupistych nagrodach - czyhaj więc bacznie na wiadomość!</div>
                            {webview == false ?
                                (<Button direction="left" color="orange" title="Wróć do strony głównej" onClick={this.backToMain} link="/" data-ga-cat="Krok 3" data-ga-act="Potwierdzenie" data-ga-lab="Wróć do strony głównej" />) : (
                                    <Button direction="left" color="orange" title="Zamknij" onClick={this.close} link="/" data-ga-cat="Krok 3" data-ga-act="Potwierdzenie" data-ga-lab="Zamknij webview" />
                                )}
                        </div>
                    </div>
                }
            </div>
        );

    }
}


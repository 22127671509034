import 'babel-polyfill';

import React from 'react';
import Button from '../components/button';
import { Link } from 'react-router-dom';

export default class FormQuestion extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            count: this.props.countForm,
            answer: this.props.answer,
            answerError: false,
            awardsError: false,
            time: this.props.time
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            time: nextProps.time
        })
    }

    componentDidMount() {
        document.querySelector('head title').innerText = 'Cheetos - Konkurs Fashionista - wybierz nagrodę i odpowiedz na pytanie konkursowe';
        document.querySelector('head meta[name="description"]').setAttribute('content', 'Weź udział w konkursie Fashionista! Wybierz upragnioną nagrodę i udziel odpowiedzi na pytanie konkursowe.');
        document.querySelector('head meta[name="keywords"]').setAttribute('content', 'cheetos, fashionista, nagrody, nagroda, konkurs, pytanie konkursowe');
        
        
    }

    handler = e => {
        e.preventDefault();
        if (this.props.valid.answer.length == 0) {
            this.setState({
                answerError: true
            })
        } else {
            this.setState({
                answerError: false
            })
        }

        if (this.props.valid.selectAward == 0) {
            this.setState({
                awardsError: true
            }, () => {
                this.props.awardsErrorHandler(true);
            })
        } else {
            this.setState({
                awardsError: false
            }, () => {
                this.props.awardsErrorHandler(false);
            });
        }

        setTimeout(() => {
            if (this.state.awardsError == false && this.state.answerError == false) {
                if (typeof ga === 'function') ga('send', 'event', 'Krok 1', 'Przejście do kroku 2', 'Dalej', 1);
                if(window.location.search.indexOf('webview') > -1){
                    window.location.href = `/${window.location.search}#/formularz/krok-2`;
                    
                } else {
                    window.location.href = '/#/formularz/krok-2';

                }
            }else {
                if (typeof ga === 'function') ga('send', 'event', 'Krok 1', 'Przejście do kroku 2', 'Dalej', 0);
            }
        }, 1000)
    }

    render() {
        const webview = (window.location.search.indexOf('webview') > -1);
        
        return (
            <div className="form-question bg--lightest-grey">
                <div className="form-question--inner">
                    <h2 className="color--grey">Udziel odpowiedzi na pytanie:</h2>
                    <span className="color--orange subtitle">Dlaczego wybrana nagroda jest najbardziej wychrupista ze wszystkich?</span>

                    <textarea className={`color--grey bg--white question ${this.state.answerError ? 'error' : ''}`}
                        maxLength="160"
                        id="textarea"
                        value={this.state.answer}
                        onChange={e => {
                            this.setState({ count: e.target.value.length, answer: e.target.value });
                            this.props.answerHandler(e.target.value, e.target.value.length)
                        }} />
                    {this.state.answerError &&
                        <div className="error color--red">Aby przejść dalej, musisz odpowiedzieć na pytanie.</div>
                    }
                    <span className="color--grey counter">{this.state.count}/160 znaków</span>
                    <div className="form-question--button-box">
                        {webview == false &&
                            <Link to="/" className="color--orange return" data-ga-cat="Krok 1" data-ga-act="Wróć do strony głównej">Wróć do strony głównej</Link>
                        }

                        <Button direction="right" color="orange" title="Dalej" onClick={this.handler} link="/formularz/krok-2" />
                        
                    </div>
                </div>
            </div>
        );

    }
}


import 'babel-polyfill';

import React from 'react';
import Button from '../components/button';


export default class ContestInfo extends React.Component {

    constructor(props) {
        super(props);

    }

    handler = e => {
        if (typeof ga === 'function') ga('send', 'event', 'Strona główna', 'KV', 'Weź udział');
    }

    render() {
        return (
            <div className="contest-info">
                <div className="contest-info--description">
                    <span className="color--grey min-text">konkurs trwa do</span>
                    <span className="color--orange big-text">31 sierpnia 2018</span>
                </div>
                <Button direction="right" title="Weź udział" onClick={this.handler} link="/formularz/krok-1" />
            </div>
        );
    }
}


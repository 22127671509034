import 'babel-polyfill';

import React from 'react';
import Slider from 'react-slick';
import DownButton from '../components/down-button';
import Button from '../components/button';

require('es6-promise').polyfill();
require('fetch-everywhere');


export default class Awards extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            time: '',
            endContest: '',
            awards: []
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            time: nextProps.time,
            endContest: nextProps.endContest
        }, () => {
            if (this.state.time == true) {
                this.getData();
            }
            this.getSliderData();
        });

    }

    getData() {
        fetch('/api/v1/contest_info?_format=json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    knowHow: resp
                });
            });

    }

    getSliderData() {
        fetch('/api/v1/slider_awards?_format=json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    awards: resp
                });
            });
    }

    handler() {
        if (typeof ga === 'function') ga('send', 'event', 'Strona główna', 'Nagrody', 'Weź udział');
    }



    PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <button type="button" data-role="none" className={className} onClick={onClick} data-ga-cat="Strona główna" data-ga-act="Nagrody" data-ga-lab="Strzałka w lewo"></button>
        );
    }

    NextArrow(props) {
        const { className, onClick } = props;
        return (
            <button type="button" data-role="none" className={className} onClick={onClick} data-ga-cat="Strona główna" data-ga-act="Nagrody" data-ga-lab="Strzałka w prawo"></button>
        );
    }


    render() {
        const settings = {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: false,
            nextArrow: <this.NextArrow onClick={this.nextHandle} />,
            prevArrow: <this.PrevArrow onClick={this.prevHandle} />
        };
        let html, buttonText, act;
        if (this.state.time == true && this.state.knowHow && this.state.endContest == false) {
            buttonText = 'Poznaj zwycięzców';
            act = 'Nagrody';
            html = (<div>
                <h2 className="color--white text--uppercase">{this.state.knowHow.title}</h2>
                <img src="resources/images/pack.png" className="pack" />
                <ul>
                    <li><span className="color--white">1.</span><span className="color--grey">{this.state.knowHow.content[0]}</span></li>
                    <li><span className="color--white">2.</span><span className="color--grey">{this.state.knowHow.content[1]}</span></li>
                    <li><span className="color--white">3.</span><span className="color--grey">{this.state.knowHow.content[2]}</span></li>
                    <li><span className="color--white">4.</span><span className="color--grey">{this.state.knowHow.content[3]}</span></li>
                    <li><span className="color--white">5.</span><span className="color--grey">{this.state.knowHow.content[4]}</span></li>
                </ul>
                <Button direction="right" color="grey" title="Weź udział" onClick={this.handler} link="/formularz/krok-1" />
            </div>);
        } else if (this.state.time == false && this.state.endContest == false) {
            buttonText = 'Obejrzyj video chestera';
            act = 'Regulamin';
            html = (<div><div className="color--white text-min text--uppercase">konkurs startuje</div>
                <div className="color--white text-big text--uppercase">18 czerwca</div></div>);
        } else if (this.state.endContest == true) {
            buttonText = 'Poznaj zwycięzców';
            act = 'Regulamin';
            html = (<div><div className="color--white text-min text--uppercase">PRZYJMOWANIE ZGŁOSZEŃ W KONKURSIE ZOSTAŁO ZAKOŃCZONE</div>
            <div className="color--white text-big text--uppercase">31 sierpnia 2018</div></div>);
        }
        return (
            <section className="section awards">
                <div className="awards--inner">
                    <div className={`left-side ${this.state.time == true ? 'know-how' : ''}`}>

                        {html}

                        <img src="resources/images/chester-hand.png" className="chester" />
                    </div>
                    <div className="right-side">
                        <div className="awards--title text--uppercase text--semibold color--grey">
                            {this.state.endContest == true ? 'Codziennie do wygrania były' : 'Codziennie do wygrania'}
                            
                        </div>
                        {this.state.awards.length > 0 &&
                            <Slider {...settings} className="awards--slider">
                                <div className="awards--slider--single-slide">
                                    <img src={this.state.awards[3].imgUrl} />
                                    <div className="color--orange awards--slider--quantity text--semibold">
                                        {this.state.awards[3].amount} x
                                </div>
                                    <div className="awards--slider--name text--uppercase text--semibold color--grey">
                                        {this.state.awards[3].name}
                                    </div>
                                </div>
                                <div className="awards--slider--single-slide">
                                    <img src={this.state.awards[1].imgUrl} />
                                    <div className="color--orange awards--slider--quantity text--semibold">
                                        {this.state.awards[1].amount} x
                                </div>
                                    <div className="awards--slider--name text--uppercase text--semibold color--grey">
                                        {this.state.awards[1].name}
                                    </div>
                                </div>
                                <div className="awards--slider--single-slide">
                                    <img src={this.state.awards[4].imgUrl} />
                                    <div className="color--orange awards--slider--quantity text--semibold">
                                        {this.state.awards[4].amount} x
                                </div>
                                    <div className="awards--slider--name text--uppercase text--semibold color--grey">
                                        {this.state.awards[4].name}
                                    </div>
                                </div>
                                <div className="awards--slider--single-slide">
                                    <img src={this.state.awards[0].imgUrl} />
                                    <div className="color--orange awards--slider--quantity text--semibold">
                                        {this.state.awards[0].amount} x
                                </div>
                                    <div className="awards--slider--name text--uppercase text--semibold color--grey">
                                        {this.state.awards[0].name}
                                    </div>
                                </div>
                                <div className="awards--slider--single-slide">
                                    <img src={this.state.awards[2].imgUrl} />
                                    <div className="color--orange awards--slider--quantity text--semibold">
                                        {this.state.awards[2].amount} x
                                </div>
                                    <div className="awards--slider--name text--uppercase text--semibold color--grey">
                                        {this.state.awards[2].name}
                                    </div>
                                </div>

                            </Slider >
                        }
                    </div>
                </div>
                <DownButton title={buttonText} cat="Strona glowna" act={act} lab={buttonText}/>
            </section>
        );
    }
}


import 'babel-polyfill';

import React from 'react';
import Slider from 'react-slick';
import Button from '../components/button';

require('es6-promise').polyfill();
require('fetch-everywhere');

export default class Movie extends React.Component {
    constructor(props) {
        super(props);

        this.apiKey = 'AIzaSyAq7KqoA8-EC45dm689vKLO2r4_g6WMIy0';
        this.apiUrl = 'https://www.googleapis.com/youtube/v3/videos';
        this.jsonUrl = '/api/v1/movies?_format=json';

        this.getMovies();

        this.state = {
            actualVideoPlay: 0,
            allIds: '',
            thumbImg: [],
            bigImg: [],
            titles: [],
            showSection: true,
            increment: 0
        };
    }

    getMovies() {
        fetch(this.jsonUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    allIds: resp.movies,
                    showSection: resp.show_section
                }, () =>{
                    this.getDataFromApi();
                });
                /* resp.movies.forEach((element, index) => {
                    this.getDataFromApi(element, index);
                }); */
            });
    }

    getDataFromApi(elem, index) {
        if (this.state.increment < 5) {
            fetch(`${this.apiUrl}?part=snippet&id=${this.state.allIds[this.state.increment]}&key=${this.apiKey}`)
                .then(resp => resp.json())
                .then(resp => {
                    this.setState({
                        thumbImg: [...this.state.thumbImg, resp.items[0].snippet.thumbnails.high ? resp.items[0].snippet.thumbnails.high.url : resp.items[0].snippet.thumbnails.default.url],
                        bigImg: [...this.state.bigImg, resp.items[0].snippet.thumbnails.maxres ? resp.items[0].snippet.thumbnails.maxres.url : resp.items[0].snippet.thumbnails.default.url],
                        titles: [...this.state.titles, resp.items[0].snippet.title],
                        increment: this.state.increment + 1
                    }, () => {
                        this.getDataFromApi();
                    });

                });

        } else {
        }
    }

    changeVideo = (e) => {
        e.preventDefault();
        let videoIndex = 0;
        if (typeof (YT) != 'undefined' && this.player.a != null) {
            this.player.destroy();
        }
        videoIndex = this.state.allIds.indexOf(e.target.parentNode.dataset.vidid);
        this.setState({
            actualVideoPlay: e.target.parentNode.dataset.vidid
        })

    }

    addYTPlayerApi() {
        if (typeof (YT) == 'undefined' || typeof (YT.Player) == 'undefined') {
            var tag = document.createElement('script');

            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            window.onYouTubeIframeAPIReady = () => {
                this.playVideo();
                if (typeof ga === 'function') ga('send', 'event', 'Strona główna', 'Video,', this.state.titles[this.state.actualVideoPlay]);
            }

        } else {
            this.playVideo();
            if (typeof ga === 'function') ga('send', 'event', 'Strona główna', 'Video,', this.state.titles[this.state.actualVideoPlay]);
        }
    }

    playVideo() {

        this.player;

        this.player = new YT.Player('player', {
            height: '360',
            width: '640',
            videoId: this.state.allIds[this.state.actualVideoPlay],
            playerVars: {
                controls: 1,
                showinfo: 0,
                rel: 0,
                showsearch: 0
            },
            events: {
                'onReady': onPlayerReady
            }
        });

        function onPlayerReady(event) {
            event.target.setVolume(100);
            event.target.playVideo();
        }

    }

    render() {
        const settings = {
            dots: false,
            slidesToShow: 5,
            slidesToScroll: 5,
            arrows: false,
            infinite: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3.5,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2.2,
                        slidesToScroll: 1,
                    }
                }/* ,
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1.2,
                        slidesToScroll: 1,
                    }
                } */
            ]
        };
        if (this.state.showSection == false) {
            setTimeout(() => {
                if (document.querySelector('.hide-video') != null) {
                    document.querySelector('.hide-video').classList.add('hidden');
                }
                if (document.querySelector('.reg--mod') != null) {
                    if (document.querySelector('.reg--mod .down-button') != null) {
                        document.querySelector('.reg--mod .down-button').classList.add('hidden');

                    }

                } else {

                    if (document.querySelector('.awards .down-button') != null) {

                        document.querySelector('.awards .down-button').classList.add('hidden');
                    }
                }
            }, 2000)
            return null;
        } else {
            if (document.querySelector('.hide-video') != null) {

                document.querySelector('.hide-video').classList.remove('hidden');
            }
            if (document.querySelector('.reg--mod') != null) {
                if (document.querySelector('.reg--mod .down-button') != null) {

                    document.querySelector('.reg--mod .down-button').classList.remove('hidden');
                }
            } else {

                if (document.querySelector('.awards .down-button') != null) {

                    document.querySelector('.awards .down-button').classList.remove('hidden');
                }
            }

            return (

                <section className="section videos bg--lightest-grey" >

                    <div className="videos--inner">
                        <div className="videos--box">
                            <div className="videos--main-video">
                                <img src={this.state.bigImg[this.state.actualVideoPlay]} />
                                <div className="bg--grey layer"></div>
                                <div className="videos--main-video--info">
                                    <span className="color--orange look">Obejrzyj</span>
                                    <div className="color--white title text--uppercase">{this.state.titles[this.state.actualVideoPlay]}</div>
                                    <Button direction="right" title="Odtwarzaj" onClick={this.addYTPlayerApi.bind(this)} link="#" />
                                    <div id="player"></div>
                                </div>
                            </div>

                            <Slider {...settings} className="videos--thumb">
                                {this.state.thumbImg.map((item, index) => (

                                    <div className={`videos--thumb--single${index == this.state.actualVideoPlay ? ' active' : ''}`} onClick={this.changeVideo.bind(this)} data-vidid={index}>
                                        <div className="bg--grey layer"></div>
                                        <img src={item} />
                                        <div className="play-ico"></div>
                                    </div>

                                ))}
                            </Slider>

                        </div>
                    </div>
                </section>
            );
        }

    }
}


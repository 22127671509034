import 'babel-polyfill';

import React from 'react';
import { scrollTo } from '../helpers/scrollTo';

export default class DownButton extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            width: window.screen.width
        };

        this.onResize();
    }

    onResize() {
        window.addEventListener('resize', () => {
            this.setState({
                width: window.screen.width
            });
        });
    }

    handleClick = (e) => {
        e.preventDefault();
        if (typeof ga === 'function') ga('send', 'event', this.props.cat, this.props.act, this.props.lab);
        const end = this._reactInternalInstance._hostParent._hostNode.nextElementSibling.offsetTop;
        this.scrollWindow(end);
    }

    scrollWindow(end) {
        scrollTo(document.documentElement, end, 300);
    }

    render() {

        if (this.state.width < 1366) {
            return null
        } else {

            return (
                <div className="down-button" onClick={this.handleClick}>
                    <span className="down-button--title color--white bg--dark-grey text--uppercase">{this.props.title}</span>
                    <span className="down-button--ico"></span>
                </div>
            );
        }
    }
}


import 'babel-polyfill';


import React from 'react';
import Header from '../partials/header';
import TopSection from '../partials/top-section';
import Awards from '../partials/awards';
import Movie from '../partials/movies';
import Footer from '../partials/footer';
import DownButton from '../components/down-button';
import Gate from '../partials/gate';
import Winners from '../partials/winners';
import Reg from '../partials/reg';

require('es6-promise').polyfill();
require('fetch-everywhere');

export default class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cookie: false,
            count: '',
            awards: []
        };
    }

    componentDidMount() {
        this.getData();
        document.querySelector('head title').innerText = 'Cheetos - Konkurs Fashionista - zawalcz o nagrody';
        document.querySelector('head meta[name="description"]').setAttribute('content', 'Konkurs Fashionista wystartował! Weź udział i zagraj o wychrupiste nagrody.');
        document.querySelector('head meta[name="keywords"]').setAttribute('content', 'cheetos, fashionista, naklejki, nagrody, nagroda, konkurs');
    }

    getData() {
        fetch('/api/v1/countdown?_format=json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    count: resp
                });
            });

        fetch('/api/v1/main_awards?_format=json', {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          }
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    awards: resp
                });
            });
    }

    render() {
        return (

            /*<div>
                {this.state.cookie == true ? (*/
            <div>
                <section className="section section--main">
                    <Header time={this.state.count.endCount} newtime={this.state.count}/>
                    <TopSection time={this.state.count} awards={this.state.awards} />
                    <DownButton title="Zobacz nagrody" cat="Strona glowna" act="KV" lab="Zobacz nagrody" />
                </section>
                <Awards time={this.state.count.endCount} endContest={this.state.count.contestFinished} />
                {this.state.count.endCount == true &&
                    <Winners />
                }
                {this.state.count.endCount == true &&
                    <div className="reg--mod">
                        <Reg awards={this.state.awards} endContest={this.state.count.contestFinished} />
                        {this.state.count.contestFinished == false &&
                            <DownButton title="obejrzyj video Chestera" cat="Strona glowna" act="Regulamin" lab="Obejrzyj video Chestera" />
                        }
                    </div>
                }
                {this.state.count.contestFinished == false &&
                    <Movie />

                }
                <Footer time={this.state.count} />
            </div>

        );
    }
}

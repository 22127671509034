import 'babel-polyfill';

import React from 'react';
import { Link } from 'react-router-dom';
import { scrollTo } from '../helpers/scrollTo';

export default class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openMenu: false,
            endCount: '',
            newtime: ''
        }



    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            endCount: nextProps.time,
            newtime: nextProps.newtime
        }, () => {
        });
    }

    componentDidMount() {
        setTimeout(() => {
            this.checkUrl(window.location.hash.split('#')[2]);
        }, 2000)
    }

    checkUrl(urlName) {
        switch (urlName) {
            case 'konkurs':
                this.scrollWindow(0);
                break;
            case 'nagrody':
                const awardPoint = document.getElementsByClassName('awards')[0] ? document.getElementsByClassName('awards')[0].offsetTop : 0;
                this.scrollWindow(awardPoint);
                break;
            case 'zwyciezcy':
                const winnersPoint = document.getElementsByClassName('winners')[0] ? document.getElementsByClassName('winners')[0].offsetTop : 0;
                this.scrollWindow(winnersPoint);
                break;
            case 'regulamin':
                const regPoint = document.getElementsByClassName('reg--mod')[0] ? document.getElementsByClassName('reg--mod')[0].offsetTop : 0;
                this.scrollWindow(regPoint);
                break;
            case 'video':
                const videoPoint = document.getElementsByClassName('videos')[0] ? document.getElementsByClassName('videos')[0].offsetTop : 0;
                this.scrollWindow(videoPoint);
                break;
        }
    }

    clickCheckUrl = e => {
        const urlName = e.target.getAttribute('href').split('#')[2];
        if (typeof ga === 'function') ga('send', 'event', 'Menu', e.target.innerText);
        this.setState({
            openMenu: !this.state.openMenu
        })
        this.checkUrl(urlName);

    }

    scrollWindow(end) {
        scrollTo(document.documentElement, end, 300);
    }

    openMenu = e => {
        this.setState({
            openMenu: !this.state.openMenu
        })
    }

    render() {
        return (
            <header className="header">
                <div className="header--logo">
                    <Link to="/"><img src="resources/images/logo.png" /></Link>
                </div>
                <div className="header--menu">
                    <div className={`header--menu--trigger only-mobile ${this.state.openMenu ? 'active' : ''}`} onClick={this.openMenu}>
                        <i></i><i></i><i></i>
                    </div>
                    <ul className={`${this.state.openMenu ? 'active' : ''}`}>
                        <li><Link to="/#konkurs" className="color--grey text--uppercase" onClick={this.clickCheckUrl}>Konkurs</Link></li>
                        <li><Link to="/#nagrody" className="color--grey text--uppercase" onClick={this.clickCheckUrl}>Nagrody</Link></li>
                        {this.state.endCount == true &&
                            <li><Link to="/#zwyciezcy" className="color--grey text--uppercase" onClick={this.clickCheckUrl}>Zwycięzcy</Link></li>
                        }
                        {this.state.endCount == true &&
                            <li><Link to="/#regulamin" className="color--grey text--uppercase" onClick={this.clickCheckUrl}>Regulamin</Link></li>
                        }
                        {this.state.newtime.contestFinished == false &&
                            <li className="hide-video"><Link to="/#video" className="color--grey text--uppercase" onClick={this.clickCheckUrl}>Video</Link></li>
                        }
                    </ul>
                    <div className="header--line only-desktop"></div>
                </div>
            </header>
        );
    }
}


import 'babel-polyfill';

import React from 'react';
import DownButton from '../components/down-button';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Slider from 'react-slick';

require('es6-promise').polyfill();
require('fetch-everywhere');

export default class Winners extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            datePicker: '',
            names: [],
            winnersKoszulka: [],
            winnersBluza: [],
            winnersLazybag: [],
            winnersRecznik: [],
            winnersSluchawki: [],
            error: false
        }

    }

    componentDidMount() {
        this.getData();
    }

    getWinnersList() {
        fetch(`/api/v1/winners?_format=json`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ day: this.state.datePicker.currentDay })
        })
            .then(resp => resp.json())
            .then(resp => {
                if (resp[1].length !== 0) {
                    this.setState({
                        error: false,
                        names: resp[0].names,
                        winnersKoszulka: resp[1].koszulka,
                        winnersBluza: resp[1].bluza,
                        winnersLazybag: resp[1].lazybag,
                        winnersRecznik: resp[1].recznik,
                        winnersSluchawki: resp[1].sluchawki
                    });

                } else {
                    this.setState({
                        error: true
                    });
                }

            });
    }

    getData() {
        fetch('/api/v1/winners_date?_format=json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    datePicker: resp
                }, () => {
                    this.getWinnersList();
                });

            });
    }

    handleDatepicker = e => {
        if (typeof ga === 'function') ga('send', 'event', 'Strona główna', 'Zwyciezcy', 'Kalendarz');
    }

    handleChange = (momentDate, event) => {
        const date = momentDate ? momentDate.format('DD-MM-YYYY') : undefined;
        this.setState({
            datePicker: {
                ...this.state.datePicker,
                currentDay: date
            }
        }, () => {
            this.getWinnersList();
        });
        setTimeout(() => {
            document.querySelector('.react-datepicker__input-container input').blur();

        }, 500);
    }
    onlyNumber = e => {
        const charCode = (e.which) ? e.which : e.keyCode;
        if ([].indexOf(charCode) == -1) {
            e.returnValue = false;
            if (e.preventDefault) { e.preventDefault(); }
        }
    }

    render() {

        const settings = {
            dots: false,
            slidesToShow: 5,
            slidesToScroll: 5,
            arrows: false,
            infinite: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3.5,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2.2,
                        slidesToScroll: 1,
                    }
                }
            ]
        };

        return (
            <div className="winners bg--lightest-grey">
                <div className="winners--inner">
                    <h2 className="text--uppercase color--grey">Zwycięzcy konkursu</h2>
                    <span className="winners--subtitle">Zobacz listę kocurów, które zdobyły nagrody</span>
                    <div className="winners--date-box">
                        <span>Wybierz dzień:</span>
                        <div className="datepicker-box">
                            <DatePicker
                                selected={moment(this.state.datePicker.currentDay, "DD-MM-YYYY")}
                                onChange={this.handleChange}
                                onKeyDown={this.onlyNumber}
                                onFocus={this.handleDatepicker}
                                onChangeRaw={this.props.onChange}
                                dateFormat="DD.MM.YYYY"
                                minDate={moment(this.state.datePicker.startDate, "DD-MM-YYYY")}
                                maxDate={moment(this.state.datePicker.endDate, "DD-MM-YYYY")}
                                placeholderText="DD.MM.RRR"

                            />
                            <span className="date-ico"></span>
                        </div>
                    </div>
                    {this.state.error ? (<div className="winners--error color--orange text--uppercase">Brak kocurów do obdarowania</div>) : (

                        <Slider {...settings} className="winners--list-box">
                            <div className="single-column">
                                <h4 className="color--orange text--uppercase">{this.state.names[0]}</h4>
                                <div className="color--grey winners--person-box">
                                    {this.state.winnersKoszulka != undefined &&
                                        this.state.winnersKoszulka.map((item, index) => (
                                            <div className="winner-person">{index + 1}. {item}</div>
                                        ))
                                    }

                                </div>
                            </div>
                            <div className="single-column">
                                <h4 className="color--orange text--uppercase">{this.state.names[1]}</h4>
                                <div className="color--grey winners--person-box">
                                    {this.state.winnersSluchawki != undefined &&
                                        this.state.winnersSluchawki.map((item, index) => (
                                            <div className="winner-person">{index + 1}. {item}</div>
                                        ))
                                    }

                                </div>
                            </div>
                            <div className="single-column">
                                <h4 className="color--orange text--uppercase">{this.state.names[2]}</h4>
                                <div className="color--grey winners--person-box">
                                    {this.state.winnersRecznik != undefined &&
                                        this.state.winnersRecznik.map((item, index) => (
                                            <div className="winner-person">{index + 1}. {item}</div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="single-column">
                                <h4 className="color--orange text--uppercase">{this.state.names[3]}</h4>
                                <div className="color--grey winners--person-box">
                                    {this.state.winnersBluza != undefined &&
                                        this.state.winnersBluza.map((item, index) => (
                                            <div className="winner-person">{index + 1}. {item}</div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="single-column">
                                <h4 className="color--orange text--uppercase">{this.state.names[4]}</h4>
                                <div className="color--grey winners--person-box">
                                    {this.state.winnersLazybag != undefined &&
                                        this.state.winnersLazybag.map((item, index) => (
                                            <div className="winner-person">{index + 1}. {item}</div>
                                        ))
                                    }
                                </div>
                            </div>
                        </Slider>

                    )}
                </div>
                <DownButton title="Zobacz regulamin" cat="Strona glowna" act="Regulamin" lab="Zobacz regulamin"/>
            </div>
        );
    }
}


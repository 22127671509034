import 'babel-polyfill';

import React from 'react';

require('es6-promise').polyfill();
require('fetch-everywhere');

export default class TopSection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [{}],
            time: {
                endCount: false,
                contestFinished: false
            }
        };
    }

    getDataFromApi() {
        fetch('/api/v1/footer?_format=json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    data: resp,
                    time: resp
                });
            });
    }

    componentDidMount() {
        this.getDataFromApi();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            time: nextProps.time
        })
    }

    render() {
        return (
            <footer className="footer">
                <div className="footer--inner color--light-black">
                    <div className="footer--top" dangerouslySetInnerHTML={{ __html: this.state.data[0].copyright }}></div>
                    <div className="footer--bottom">
                        <div className="footer--bottom--left">
                            <div className="footer--box1 text--medium">
                                <div className="footer--box1--text1" dangerouslySetInnerHTML={{ __html: this.state.data[0].trademarks }}></div>
                                {this.state.time.endCount ? (
                                    <div className="footer--box1--text2 text--uppercase">KONKURS TRWAŁ OD 18.08.2018 ROKU DO 31.08.2018 ROKU</div>

                                ) : (
                                    <div className="footer--box1--text2 text--uppercase">KONKURS TRWA OD 18.08.2018 ROKU DO 31.08.2018 ROKU</div>

                                )}
                            </div>
                            <div className="footer--box2 text--semibold">
                                <a href={this.state.data[0].cookies} target="_blank" className="text--uppercase" data-ga-cat="Stopka" data-ga-act="Legale" data-ga-lab="Cookies">Cookies</a>
                                {this.state.time.endCount == true &&
                                    <a href={this.state.data[0].regulamin} target="_blank" className="text--uppercase" data-ga-cat="Stopka" data-ga-act="Legale" data-ga-lab="Regulamin">Regulamin</a>

                                }
                                <a href={this.state.data[0].privacy_policy} target="_blank" className="text--uppercase" data-ga-cat="Stopka" data-ga-act="Legale" data-ga-lab="Polityka prywatności">Polityka prywatności</a>
                            </div>
                        </div>
                        <div className="footer--bottom--right">
                            <div className="footer--box3 text--semibold text--uppercase">
                                Masz chrapkę na więcej <br />
                                wychrupistych inspiracji?
                            </div>
                            <div className="footer--box4">
                                <a href={this.state.data[0].facebook} target="_blank" className="fb" data-ga-cat="Stopka" data-ga-act="Social media" data-ga-lab="Facebook">Facebook</a>
                                <a href={this.state.data[0].youtube} target="_blank" className="yt" data-ga-cat="Stopka" data-ga-act="Social media" data-ga-lab="YouTube">YouTube</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}


import 'babel-polyfill';

import React from 'react';
import Header from '../partials/header';
import Footer from '../partials/footer';
import FormSteps from '../components/form-steps';
import FormStepTwo from '../partials/form-step2';
import FormAwards from '../partials/form-awards';
import FormQuestion from '../partials/form-question';
import Gate from '../partials/gate';

export default class Form extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            form: false,
            cookie: false,
            count: '',
            selectAward: 0,
            selectAwardId: 0,
            answer: '',
            awardsError: false,
            countForm: 0,
            fb: '',
            psid: ''
        };
    }

    /* checkCookie() {
        const adultGate = this.getCookie('adultGate');
        if (adultGate == 'true') {
            this.setState({
                cookie: true
            }, () => {
                this.getData();
            });
        } else {
            this.setState({
                cookie: false
            });
        }
    } */

    componentDidMount() {
        /* this.checkCookie(); */
        this.getData();
        this.getFbApi();
    }

    /* getCookie(cname) {
        const name = `${cname}=`,
            decodedCookie = decodeURIComponent(document.cookie),
            ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
    addCookie() {
        document.cookie = `adultGate=true;expires=Thu, 01 Jan 2020 00:00:00 GMT`;
        this.setState({
            cookie: true
        });
    }

    handler() {
        this.addCookie();
        this.getData();
    } */

    getData() {
        fetch('/api/v1/countdown?_format=json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    count: resp
                });
                if (resp.contestFinished == true) {
                    if (window.location.search.indexOf('webview') > -1) {
                        window.location.href = `/${window.location.search}#/`;

                    } else {
                        window.location.href = '/#/';

                    }
                }
            });
    }

    selectHandler = (number, idNumber) => {
        this.setState({
            selectAward: number,
            selectAwardId: idNumber
        })
    }

    answerHandler = (text, countForm) => {
        this.setState({
            answer: text,
            countForm: countForm
        });
    }

    awardsErrorHandler = (awardsError) => {
        this.setState({
            awardsError: awardsError
        });
    }

    getFbApi() {

        const webview = (window.location.search.indexOf('webview') > -1);

        if (webview == true) {
            const _self = this;
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'Messenger'));

            setTimeout(() => {


                fetch('/api/v1/facebook_settings/1231?_format=json')
                    .then(resp => resp.json())
                    .then(resp => {
                        this.setState({
                            fb: resp
                        }, () => {

                            MessengerExtensions.getContext(this.state.fb[0].field_fb_app_id,
                                function success(thread_context) {
                                    _self.setState({
                                        psid: thread_context.psid
                                    }, () => {

                                    })
                                },
                                function error(err) {

                                }
                            );
                        });
                    });
            }, 1000)

        }


    }

    render() {

        const webview = (window.location.search.indexOf('webview') > -1);


        return (
            /*  <div>
                 {this.state.cookie == true ? ( */
            <div>
                <section className="section section--grey bg--lightest-grey">
                    {webview == false &&
                        <Header time={this.state.count.endCount} newtime={this.state.count} />
                    }
                    <FormSteps tab={this.props.match.params.step} />
                </section>
                {this.props.match.params.step == 1 ? (
                    <div>
                        <FormAwards
                            selectHandler={this.selectHandler}
                            selectAward={this.state.selectAward}
                            awardsError={this.state.awardsError} />
                        <FormQuestion
                            answerHandler={this.answerHandler}
                            countForm={this.state.countForm}
                            answer={this.state.answer}
                            valid={this.state}
                            awardsErrorHandler={this.awardsErrorHandler} />
                    </div>
                ) : (
                        <div>
                            <FormStepTwo globe={this.state} />
                        </div>
                    )}
                {webview == false &&
                    <Footer time={this.state.count} />
                }
            </div>
            /* ) : (
                    <div>
                        <section className="section section--grey bg--lightest-grey">
                            <Header />
                            <Gate handler={this.handler.bind(this)} />
                        </section>
                        <Footer />
                    </div>
                )}
        </div> */
        );
    }

}

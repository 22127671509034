// import 'babel-polyfill';     // uncomment this if you want to use for...of, async/await
// or other things that require babel runtime

import Preloader from './helpers/preloader';
import './helpers/ga';
import App from './containers/appContainer';

import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

window.addEventListener('load', () => {
    Preloader.hide();

    const el = document.getElementById('app');
    if (el) {
        ReactDOM.render(
            <App />,
            el
        );

    }
});

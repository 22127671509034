import 'babel-polyfill';

import React from 'react';

export default class FormSteps extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className={`form-header ${window.location.search.indexOf('webview') > -1 ? 'webview' : ''}`}>
                <div className="form-steps bg--lightest-grey">
                    <div className="form-steps--inner">
                        <div className="steps--box">
                            <div className={`step-one ${this.props.tab == 1 ? 'active' : ''}`}>
                                <span className={`text--uppercase text--semibold title ${this.props.tab == 1 ? 'color--orange' : 'color--grey'}`}>
                                    Krok 1
                                </span>
                                <span className="color--grey subtitle">Nagroda i pytanie</span>
                            </div>
                            <div className={`step-two ${this.props.tab == 2 ? 'active' : ''}`}>
                                <span className={`text--uppercase text--semibold title ${this.props.tab == 2 ? 'color--orange' : 'color--grey'}`}>
                                    Krok 2
                                </span>
                                <span className="color--grey subtitle">Kod i dane</span>
                            </div>
                        </div>
                        {this.props.tab == 1 &&
                            <div className="steps--title color--grey">
                                Wybierz nagrodę, o którą chcesz zagrać:
                            </div>
                        }
                    </div>
                </div>
            </div>
        );

    }
}


import 'babel-polyfill';

import React from 'react';
import { scrollTo } from '../helpers/scrollTo';

export default class FormAwards extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            width: window.screen.width,
            select: this.props.selectAward,
            awardsError: this.props.awardsError,
            awards: []
        };

        this.onResize();
        this.getAwards();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            awardsError: nextProps.awardsError
        });
    }

    componentDidMount() {
        scrollTo(document.documentElement, 0, 300);
    }

    getAwards() {
        fetch('/api/v1/form_awards?_format=json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    awards: resp
                });
            });
    }

    onResize() {
        window.addEventListener('resize', () => {
            this.setState({
                width: window.screen.width
            });
        });
    }

    render() {
        return (
            <div className="form-awards">
                {this.state.awards.length > 0 &&
                    <div className="form-awards--inner">
                        <div className={`single-awards bg--lightest-grey ${this.state.select == 1 ? 'active' : ''}`}
                            onClick={e => { this.setState({ select: 1 }); this.props.selectHandler(1, this.state.awards[4].id); }}
                            data-ga-cat="Krok 1" data-ga-act="Nagroda" data-ga-lab={this.state.awards[4].name}>
                            <span className="circle bg--white"></span>
                            <span className="title text--uppercase color--orange">
                                {this.state.awards[4].name}
                            </span>
                            <img src={window.matchMedia("(min-width: 1024px)").matches == true ? this.state.awards[4].imgUrlDesktop : this.state.awards[4].imgUrlMobile} />
                        </div>
                        <div className={`single-awards bg--lightest-grey ${this.state.select == 2 ? 'active' : ''}`}
                            onClick={e => { this.setState({ select: 2 }); this.props.selectHandler(2, this.state.awards[3].id); }}
                            data-ga-cat="Krok 1" data-ga-act="Nagroda" data-ga-lab={this.state.awards[3].name}>
                            <span className="circle bg--white"></span>
                            <span className="title text--uppercase color--orange">
                                {this.state.awards[3].name}
                            </span>
                            <img src={window.matchMedia("(min-width: 1024px)").matches == true ? this.state.awards[3].imgUrlDesktop : this.state.awards[3].imgUrlMobile} />
                        </div>
                        <div className={`single-awards bg--lightest-grey ${this.state.select == 3 ? 'active' : ''}`}
                            onClick={e => { this.setState({ select: 3 }); this.props.selectHandler(3, this.state.awards[2].id); }}
                            data-ga-cat="Krok 1" data-ga-act="Nagroda" data-ga-lab={this.state.awards[2].name}>
                            <span className="circle bg--white"></span>
                            <span className="title text--uppercase color--orange">
                                {this.state.awards[2].name}
                            </span>
                            <img src={window.matchMedia("(min-width: 1024px)").matches == true ? this.state.awards[2].imgUrlDesktop : this.state.awards[2].imgUrlMobile} />
                        </div>
                        <div className={`single-awards bg--lightest-grey ${this.state.select == 4 ? 'active' : ''}`}
                            onClick={e => { this.setState({ select: 4 }); this.props.selectHandler(4, this.state.awards[1].id); }}
                            data-ga-cat="Krok 1" data-ga-act="Nagroda" data-ga-lab={this.state.awards[1].name}>
                            <span className="circle bg--white"></span>
                            <span className="title text--uppercase color--orange">
                                {this.state.awards[1].name}
                            </span>
                            <img src={window.matchMedia("(min-width: 1024px)").matches == true ? this.state.awards[1].imgUrlDesktop : this.state.awards[1].imgUrlMobile} />
                        </div>
                        <div className={`single-awards bg--lightest-grey ${this.state.select == 5 ? 'active' : ''}`}
                            onClick={e => { this.setState({ select: 5 }); this.props.selectHandler(5, this.state.awards[0].id); }}
                            data-ga-cat="Krok 1" data-ga-act="Nagroda" data-ga-lab={this.state.awards[0].name}>
                            <span className="circle bg--white"></span>
                            <span className="title text--uppercase color--orange">
                                {this.state.awards[0].name}
                            </span>
                            <img src={window.matchMedia("(min-width: 1024px)").matches == true ? this.state.awards[0].imgUrlDesktop : this.state.awards[0].imgUrlMobile} />
                        </div>
                    </div>
                }
                {this.state.awardsError &&
                    <div className="color--red error">Aby przejść dalej, musisz wybrać nagrodę.</div>
                }
            </div>
        );

    }
}


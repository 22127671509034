import 'babel-polyfill';

import React from 'react';
import Main from './mainContainer';
import Form from './formContainer';
import Button from '../components/button';
import {
    HashRouter as Router,
    Route,
    Link
} from 'react-router-dom';


export default class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.screen.width,
            rodoCookie: true,
            rodoCookie2: true,
            rodo2: [
                {
                    title: '',
                    content: '',
                    button: ''
                }
            ],
            rodo: [
                {
                    title: '',
                    content: '',
                    button: ''
                }
            ]
        }

        this.onResize();
    }

    checkCookie() {
        const rodoInfo1 = this.getCookie('chesterrodo');
        const rodoInfo2 = this.getCookie('chesterrodo2');

        if (rodoInfo1 == 'true') {
            this.setState({
                rodoCookie: true
            });
        } else {
            this.setState({
                rodoCookie: false
            });
        }

        if (rodoInfo2 == 'true') {
            this.setState({
                rodoCookie2: true
            });
        } else {
            this.setState({
                rodoCookie2: false
            });
        }
    }

    getCookie(cname) {
        const name = `${cname}=`,
            decodedCookie = decodeURIComponent(document.cookie),
            ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
    addCookie() {
        document.cookie = `chesterrodo=true;expires=Thu, 01 Jan 2020 00:00:00 GMT`;
        this.setState({
            rodoCookie: true
        });
    }

    addCookie2() {
        document.cookie = `chesterrodo2=true;expires=Thu, 01 Jan 2020 00:00:00 GMT`;
        this.setState({
            rodoCookie2: true
        });
    }

    handler = e => {
        e.preventDefault();
        this.addCookie();
    }

    handler2 = e => {
        e.preventDefault();
        this.addCookie2();
    }
    getData() {
        fetch('/api/v1/countdown?_format=json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    count: resp
                });
                if (resp.contestFinished == true && window.location.hash.indexOf('formularz')) {
                    if (window.location.search.indexOf('webview') > -1) {
                        window.location.href = `/${window.location.search}#/`;

                    } else {
                        window.location.href = '/#/';

                    }
                }
            });
    }

    componentDidMount() {
        this.checkCookie();
        this.getRodo();
        this.getData();
    }

    getRodo() {
        fetch('/api/v1/popup?_format=json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ popup_id: 1234 })
        })
            .then(resp => resp.json())
            .then(resp => {

                this.setState({
                    rodo: resp
                }, () => {
                    fetch('/api/v1/popup?_format=json', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ popup_id: 1237 })
                    })
                        .then(resp => resp.json())
                        .then(resp => {

                            this.setState({
                                rodo2: resp
                            });
                        }, () => {

                        });
                });
            });
    }


    onResize() {
        window.addEventListener('resize', () => {
            this.setState({
                width: window.screen.width
            });
            /* if (window.matchMedia("(max-width: 1023px)").matches == true && window.matchMedia("(orientation: landscape)").matches) {
                document.querySelector('html').classList.add('no-scroll');
            } else {
                document.querySelector('html').classList.remove('no-scroll');
            } */
        });
    }


    render() {

        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        var iw = (iOS) ? screen.width : window.innerWidth, ih = (iOS) ? screen.height : window.innerHeight;
        const webview = window.location.search.indexOf('webview') > -1;
        //(window.matchMedia("(max-width: 1023px)").matches == true && window.matchMedia("(orientation: landscape)").matches)

        /* {(window.matchMedia("(max-width: 1023px)").matches == true && iw > ih == true && webview == false) &&
                        <div className="orientation">
                            <div className="orientation--inner">
                                <img src="resources/images/rotate.png" />
                                <div className="color--white">Obróć telefon do pionu, kocurze!</div>
                            </div>
                        </div>
                    } */

        return (

            <Router>
                <div>
                    <Route exact path='/' component={Main} />
                    <Route path='/formularz/krok-:step' component={Form} />



                    {(this.state.rodoCookie == false || this.state.rodoCookie2 == false) &&
                        <div className="rodoinfo">
                            {this.state.rodoCookie == false &&

                                <div className="rodoinfo--inner">
                                    <span className="title">{this.state.rodo[0].title}</span>
                                    <div className="description" dangerouslySetInnerHTML={{ __html: this.state.rodo[0].content }}></div>
                                    <div className="button">
                                        <a href="#" onClick={this.handler}>
                                            <div className="button--inner">
                                                <span className="button--title text--uppercase">{this.state.rodo[0].button}</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            }
                            {(this.state.rodoCookie == true && this.state.rodoCookie2 == false) &&

                                <div className="rodoinfo--inner">
                                    <span className="title">{this.state.rodo2[0].title}</span>
                                    <div className="description" dangerouslySetInnerHTML={{ __html: this.state.rodo2[0].content }}></div>
                                    <div className="button">
                                        <a href="#" onClick={this.handler2}>
                                            <div className="button--inner">
                                                <span className="button--title text--uppercase">{this.state.rodo2[0].button}</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </Router>


        );
    }
}

import 'babel-polyfill';

import React from 'react';
import Button from '../components/button';

export default class Gate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dayValue: '',
            dayValid: true,
            monthValue: '',
            monthValid: true,
            yearValue: '',
            yearValid: true,
            valid: true,
            age: true
        };
    }

    checkForm = e => {
        if (this.state.dayValue.length != 2 || this.state.dayValue > 31) {
            this.setState({ dayValid: false })
        } else {
            this.setState({ dayValid: true })
        }
        if (this.state.monthValue.length != 2 || this.state.monthValue > 12) {
            this.setState({ monthValid: false })
        } else {
            this.setState({ monthValid: true })
        }
        if (this.state.yearValue.length != 4) {
            this.setState({ yearValid: false })
        } else {
            this.setState({ yearValid: true })
        }
        setTimeout(() => {
            if (this.state.dayValid == false || this.state.monthValid == false || this.state.yearValid == false) {
                this.setState({
                    valid: false
                })
            } else {
                this.setState({
                    valid: true
                }, () => {
                    this.checkAge();
                })
            }
        }, 500)
    }

    checkAge() {
        let myDate = new Date(`${this.state.monthValue}/${this.state.dayValue}/${this.state.yearValue}`),
            now = new Date(),
            month_of_birth = myDate.getMonth(),
            day_of_birht = myDate.getDate(),
            year_of_birth = myDate.getYear(),
            now_month = now.getMonth(),
            now_day = now.getDate(),
            now_year = now.getYear(),
            age = now_year - year_of_birth;
        if (now_month < month_of_birth) {
            age--;
        } else if ((now_month == month_of_birth) && (now_day < day_of_birht)) {
            age--;
            if (age < 0) {
                age = 0;
            }
        }
        if (isNaN(age) || age < 13) {
            this.setState({
                age: false
            })
        } else {
            this.setState({
                age: true
            }, () => {
                this.props.handler();
            })
        }

    }

    onlyNumber = e => {
        const charCode = (e.which) ? e.which : e.keyCode;
        if ([8, 9, 46, 37, 39, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 35, 36, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105].indexOf(charCode) == -1) {
            e.returnValue = false;
            if (e.preventDefault) { e.preventDefault(); }
        }
    }

    render() {
        return (
            <div className="gate">
                <h1 className="text--uppercase color--grey text--semibold gate--title">Hola, Hola!</h1>
                <div className="gate--box">
                    <div className="gate--description color--grey text--semibold">
                        Musimy sprawdzić, czy jesteś wystarczająco dużym kocurem, żeby móc odwiedzić tę stronę.
                    </div>
                    <div className="gate--form">
                        <input type="text" id="day"
                            className={`${this.state.dayValid == false ? 'error' : ''} color--grey text--semibold`}
                            placeholder="dd"
                            maxLength={2}
                            min="1"
                            max="31"
                            onChange={e => { this.setState({ dayValue: e.target.value }) }}
                            onKeyDown={this.onlyNumber} />
                        <input type="text" id="month"
                            className={`${this.state.monthValid == false ? 'error' : ''} color--grey text--semibold`}
                            placeholder="mm"
                            maxLength={2}
                            min="1"
                            max="12"
                            onChange={e => { this.setState({ monthValue: e.target.value }) }}
                            onKeyDown={this.onlyNumber} />
                        <input type="text" id="year"
                            className={`${this.state.yearValid == false ? 'error' : ''} color--grey text--semibold`}
                            placeholder="yyyy"
                            maxLength={4}
                            onChange={e => { this.setState({ yearValue: e.target.value }) }}
                            onKeyDown={this.onlyNumber} />
                    </div>
                    {this.state.valid == false &&
                        <span className="info--form color--red">Aby wejść na stronę, wypełnij wszystkie pola.</span>
                    }
                    {this.state.age == false &&
                        <span className="info--form color--red">Jesteś zbyt młody, aby wejść na stronę.</span>
                    }
                    <Button direction="right" title="Wejdź" onClick={this.checkForm} link="#" />
                </div>
            </div>
        );
    }

}

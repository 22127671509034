import 'babel-polyfill';

import React from 'react';
import Countdown from '../components/countdown';
import ContestInfo from '../components/contest-info';

export default class TopSection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            time: '',
            awards: [],
            width: window.screen.width
        };
        this.onResize();
    }

    onResize() {
        window.addEventListener('resize', () => {
            this.setState({
                width: window.screen.width
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            time: nextProps.time,
            awards: nextProps.awards
        });

    }

    render() {

        let component;

        if (this.state.time.endCount == true) {

            component = <ContestInfo />;

        } else if (this.state.time.endCount == false) {

            component = <Countdown time={this.state} />;

        }
        
        return (
            <div className="top-section">
                {this.state.time.contestFinished == true ?
                    (
                        <div className="left-side contest-finish">

                            <h1 className="text--uppercase">
                                <span className="color--orange">Konkurs Chestera</span>
                                <span className="color--grey">dobiegł końca</span>
                            </h1>

                        </div>
                    ) : (
                        <div className="left-side">

                            <h1 className="text--uppercase">
                                <span className="color--orange">Weź udział <br /> w konkursie</span>
                                <span className="color--grey">i graj o</span>
                            </h1>
                            <div className="top-section--subtitle color--grey">wychrupiste nagrody by Chester!</div>
                            {component}
                        </div>

                    )
                }


                {window.matchMedia("(min-width: 1024px)").matches == true &&
                    <div className="right-side">
                        <div className="top-awards">
                            <img src={this.state.awards.length > 0 ? this.state.awards[0].desktop : ''} />
                            <img src={this.state.awards.length > 0 ? this.state.awards[1].desktop : ''} />
                            <img src={this.state.awards.length > 0 ? this.state.awards[2].desktop : ''} />
                            <img src={this.state.awards.length > 0 ? this.state.awards[3].desktop : ''} />
                        </div>
                        <div className="bottom-awards">
                            <img src={this.state.awards.length > 0 ? this.state.awards[4].desktop : ''} />
                        </div>
                    </div>
                }

                {window.matchMedia("(max-width: 1023px)").matches == true &&
                    <img src={this.state.awards.length > 0 ? this.state.awards[4].mobile : ''} className="lazybag" />
                }

                <img src={window.matchMedia("(min-width: 1024px)").matches == true ? 'resources/images/chester.png' : 'resources/images/mobile/chester.png'} className="chester" />

            </div>
        );
    }
}


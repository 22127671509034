import 'babel-polyfill';

import React from 'react';
import DownButton from '../components/down-button';

export default class Reg extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            width: window.screen.width,
            awards: [
                'resources/images/awards/koszulka.png',
                'resources/images/awards/sluchawki.png',
                'resources/images/awards/recznik.png',
                'resources/images/awards/bluza.png',
                'resources/images/awards/lazybag.png'
            ],
            endContest: false
        };
        this.onResize();
    }

    onResize() {
        window.addEventListener('resize', () => {
            this.setState({
                width: window.screen.width
            });
        });
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            awards: nextProps.awards,
            endContest: nextProps.endContest
        });
    }

    handler() { }

    render() {

        return (
            <div className="top-section">
                <div className={`left-side ${this.state.endContest ? 'contest-finish' : ''}`}>
                    {this.state.endContest == false && 
                        <h1 className="text--uppercase">
                            <span className="color--grey">Pierwsze koty <br /> za płoty</span>
                        </h1>
                    
                    }
                    <div className="button">
                        <a href="https://konkursfashionista.cheetos.pl/sites/default/files/2018-09/Regulamin.pdf" target="_blank" data-ga-cat="Strona główna" data-ga-act="Regulamin" data-ga-lab="Zobacz regulamin">
                            <div className="button--inner">
                                <span className="button--title text--uppercase">Zobacz regulamin</span>
                                <span className="button--ico--right"></span>
                            </div>
                        </a>
                    </div>
                    <p className="contest-rules-info">Uwaga, nastąpiła aktualizacja regulaminu. Z przyczyn niezależnych od Organizatora Konkursu zmianie uległy pkt 5 i 11 regulaminu w zakresie daty zgłoszenia do Konkursu za pośrednictwem funkcji wiadomości z wykorzystaniem bota.</p>
                    <div className="top-section--subtitle color--grey">Masz pytania? Skontaktuj się z nami!</div>
                    <a href="mailto:konkurs@cheetos.pl" className="color--orange" data-ga-cat="Strona główna" data-ga-act="Regulamin" data-ga-lab="Kontakt">konkurs@cheetos.pl</a>
                </div>

                {window.matchMedia("(min-width: 1024px)").matches == true &&
                    <div className="right-side">
                        <div className="top-awards">
                            <img src={this.state.awards.length > 0 ? this.state.awards[0].desktop : ''} />
                            <img src={this.state.awards.length > 0 ? this.state.awards[1].desktop : ''} />
                            <img src={this.state.awards.length > 0 ? this.state.awards[2].desktop : ''} />
                            <img src={this.state.awards.length > 0 ? this.state.awards[3].desktop : ''} />
                        </div>
                        <div className="bottom-awards">
                            <img src={this.state.awards.length > 0 ? this.state.awards[4].desktop : ''} />
                        </div>
                    </div>
                }

                {window.matchMedia("(max-width: 1023px)").matches == true &&
                    <img src={this.state.awards.length > 0 ? this.state.awards[4].mobile : ''} className="lazybag" />
                }

                <img src={window.matchMedia("(min-width: 1024px)").matches == true ? 'resources/images/chester.png' : 'resources/images/mobile/chester.png'} className="chester" />

            </div>
        );
    }

}

